import _validate from "./validate";
var exports = {};
const {
  validate,
  ValidationError
} = _validate;
exports = {
  validate,
  ValidationError
};
export default exports;
const _validate2 = exports.validate,
      _ValidationError = exports.ValidationError;
export { _validate2 as validate, _ValidationError as ValidationError };